import React from "react"
import { Link } from "gatsby"

import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import "react-tabs/style/react-tabs.css"

import Layout from "../../../components/es/layout"
//import Image from "../components/image"
import SEO from "../../../components/es/seo"

import MainImg from "./../../../images/main_bg.jpg"

import shape1 from "./../../../images/dec/1.png"
import shape2 from "./../../../images/dec/2.png"
import shape3 from "./../../../images/dec/3.png"
import shape4 from "./../../../images/dec/4.png"
import shape5 from "./../../../images/dec/5.png"
import shape6 from "./../../../images/dec/6.png"
import shape7 from "./../../../images/dec/7.png"
import shape8 from "./../../../images/dec/8.png"

import product6 from "./../../../images/es/moonamar-aceite-de-comino-negro-en-capsulas-halal.png"

const CorokotovoUlje = () => (
  <Layout>
    <SEO
      title="IMMMUNO NATURA – ACEITE DE COMINO NEGRO EN CÁPSULAS “HALAL”"
      description="Aceite IMMMUNO NATURA de comino negro en cápsulas  – aceite de comino negro en cápsulas con el certificado “halal”"
    />

    <section
      id="_heroPage"
      className="hero is-large"
      style={{ backgroundImage: `url(${MainImg})` }}
    >
      <div id="bgHero">
        <span className="shape1">
          <img src={shape1} alt="Shape 1" />
        </span>
        <span className="shape2">
          <img src={shape2} alt="Shape 2" />
        </span>
        <span className="shape3">
          <img src={shape3} alt="Shape 3" />
        </span>
        <span className="shape4">
          <img src={shape4} alt="Shape 4" />
        </span>
        <span className="shape5">
          <img src={shape5} alt="Shape 5" />
        </span>
        <span className="shape6">
          <img src={shape6} alt="Shape 6" />
        </span>
        <span className="shape7">
          <img src={shape7} alt="Shape 7" />
        </span>
        <span className="shape8">
          <img src={shape2} alt="Shape 8" />
        </span>
      </div>
      <div className="hero-body" />
    </section>

    <span className="_line" />

    <section id="_oProizvodu" className="section">
      <div className="container">
        <div className="columns">
          <div className="column">
            <div className="_slide">
              <img
                className="_img"
                src={product6}
                alt="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"
                title="ACEITE DE COMINO NEGRO EN CÁPSULAS 'HALAL'"
              />
              <div className="_info">
                <div className="_inner">
                  <span>IMMMUNO NATURA</span>
                  <div className="title">
                    Aceite de comino negro en cápsulas (90 Cápsulas)
                  </div>
                  <div className="subtitle">Contenido: 90 cápsulas</div>
                  <div className="price">10,90 €</div>
                </div>
                <div className="_buttonShop">
                  <Link to="/es/formulario-para-pedidos">PEDIDOS</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section id="_pageContent" className="section">
      <span className="_dec2">
        <img src={shape8} alt="Dec 2" />
      </span>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <Tabs>
              <TabList>
                <Tab>Descripción del producto</Tab>
                <Tab>Ingredientes y modo de empleo</Tab>
              </TabList>

              <TabPanel>
                <div className="content">
                  <p>
                    Las cápsulas de comino negro son la alternativa ideal para
                    aquellas personas que no tolerarn el sabor del aceite de
                    comino. Son aptas tanto para adultos como para niños.
                  </p>
                  <p>
                    <strong>ELABORACIÓN DEL ACEITE</strong>: Prensado en frío
                    sin ninguna otra elaboración - 100% aceite de comino negro{" "}
                    <br /> <strong>CALIDAD DEL ACEITE</strong>: Extra Virgen{" "}
                    <br /> <strong>ENVOLTORIO DE LAS CÁPSULASE</strong>: Geltina
                    con certificado Halal
                    <br /> <strong>CLASE DE SEMILLAS</strong>: Nigella Sativa{" "}
                    <br /> <strong>ORIGEN DE LAS SEMILLAS</strong>: Egipto{" "}
                    <br /> <strong>RESISTENCIA</strong>: en el aceite de comino
                    negro es difícil que aparezcan gérmenes u hongos debido a su
                    composición. El envoltorio de las cápsulas le protege de la
                    oxidación o contaminación.
                    <br />
                    <strong>CONSERVACIÓN</strong>: Conservar preferentemente en
                    lugares frescos y secos.
                  </p>
                  <table>
                    <tbody>
                      <tr>
                        <td width="125">
                          <p>
                            <strong>Valor nutritivo</strong>
                          </p>
                        </td>
                        <td width="67">
                          <p>
                            <strong>por 100 g</strong>
                          </p>
                        </td>
                        <td width="81">
                          <p>
                            <strong>por 3 capsulás</strong>
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Valor energético</p>
                        </td>
                        <td>
                          <p>
                            3.039 kJ
                            <br /> 735 kcal
                          </p>
                        </td>
                        <td>
                          <p>
                            62,36 kJ
                            <br /> 15,08 kcal
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Grasas de las
                            <br />
                            <em>cuales saturadas</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            67,2 g<br /> 12,2 g
                          </p>
                        </td>
                        <td>
                          <p>
                            1,38 g<br /> 0,25 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>
                            Hidratos de carbon de los
                            <br />
                            <em>cuales azúcares</em>
                          </p>
                        </td>
                        <td>
                          <p>
                            14,5 g<br /> &#60; 0,50 g
                          </p>
                        </td>
                        <td>
                          <p>
                            0,3 g<br /> &#60; 0,02 g
                          </p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Proteínas</p>
                        </td>
                        <td>
                          <p>18,1 g</p>
                        </td>
                        <td>
                          <p>0,37 g</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <p>Sal</p>
                        </td>
                        <td>
                          <p>0,0045 g</p>
                        </td>
                        <td>
                          <p>&#60; 0,001 g</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="content">
                  <p>
                    Lado c&#225;psulas de aceite de comino negro de Moonamaar
                    est&#225;n compuestas del mejor aceite egipcio de comino
                    negro y de una envoltura de gelatina certificada como Halal.
                  </p>
                  <br />
                  <h3>Ingredientes:</h3>
                  <p dir="ltr">
                    El aceite de comino negro es rico en diferentes vitaminas,
                    minerales, prote&#237;nas, grasas no saturadas,
                    fitoesteroles, aceites esenciales as&#237; como algunas
                    sustancias que solo se encuentran en el comino negro
                    (Nigellona, Thimokinona, Nigelina).
                  </p>
                  <h3>Modo de empleo:</h3>
                  <p>
                    El aceite de comino negro se utiliza en la medicina
                    tradicional como regulador del sistema inmunol&#243;gico,
                    como ayuda en los tratamientos a distintas alergias y como
                    refuerzo de nuestras defensas. Para notar su efectos
                    recomendamos tomar ocho semanas como m&#237;nimo el aceite.
                    Para aquellos que no siguen una dieta alimenticia muy sana,
                    el aceite de comino negro se recomienda como complemento
                    alimenticio.
                  </p>
                  <h3>Dosis recomendada:</h3>
                  <p>
                    Adultos: 3 veces al d&#237;a dos o tres c&#225;psulas a la
                    hora de las comidas. Como prevenci&#243;n, se toman 2-3
                    c&#225;psulas una vez al d&#237;a para el desayuno.
                  </p>
                  <p>
                    Ni&#241;os: 2-3 veces al d&#237;a 1-2 capsulas a la hora de
                    las comidas. Como prevenci&#243;n, se toma 1 capsula a la
                    hora del desayuno.
                  </p>
                  <h3>Consejos:</h3>
                  <p>
                    La dosis recomendada no se debe sobrepasar.
                    <br />
                    Los suplementos alimenticos no se tienen que utilizar como
                    sustitutivos de una alimentaci&#243;n completa y sana.
                    <br />
                    Mantener fuera del alcance de los ni&#241;os.
                    <br />
                    Mantener en fr&#237;o y fuera del alcance de la luz.
                  </p>
                </div>
              </TabPanel>
            </Tabs>
          </div>
        </div>
      </div>
      <span className="_dec3">
        <img src={shape6} alt="Dec 3" />
      </span>
    </section>
  </Layout>
)

export default CorokotovoUlje
